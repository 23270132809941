import './Loader.scss';
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as LogoIcon } from '../../assets/img/leadqualifier-icon.svg';

type LoaderProps = {
    forceVisible?: boolean;
    inner?: boolean;
    size?: number;
};

export const Loader = (props: LoaderProps) => {
    const visible = useAppSelector((state) => state.load.status);

    if (!(visible || props.forceVisible)) {
        return null;
    }

    return (
        <div className={`loader ${props.inner ? 'loader--inner' : ''}`}>
            <LogoIcon
                className='loader__component'
                height={props.size ?? 100}
                width={props.size ?? 100}
            />
        </div>
    );
};
