import { User } from './../Views/Auth/types';
import CryptoJs from 'crypto-js';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { GetApi, PostApi } from './ApiServices';
import { AxiosError } from 'axios';

const cookies = new Cookies();
const appSecret = 'sales-prospector-secret';
const appCookie = 'sales-prospector';
const appAdminCookie = 'sales-prospector-admin';

const lastLoginUserNameCookie = 'last-login-name';
const expires = moment().add(3, 'd').toDate();

type UserDataType = {
    uulogin: string;
    uuid: string;
    uutoken: string;
    isAdmin: boolean;
    asAdmin?: number;
};

const encodeData = (data: UserDataType) => {
    return CryptoJs.AES.encrypt(JSON.stringify(data), appSecret).toString();
};

const decodeData = (data: string) => {
    var bytes = CryptoJs.AES.decrypt(data, appSecret);
    return JSON.parse(bytes.toString(CryptoJs.enc.Utf8));
};

const getSingle = (name: keyof UserDataType, isAdmin = false) => {
    const cookie = isAdmin ? appAdminCookie : appCookie;

    if (cookies.get(cookie) !== undefined) {
        let userData = decodeData(cookies.get(cookie));
        return Object.assign([], userData)[name];
    } else {
        return false;
    }
};

export const isUserLoggednIn = () => {
    return getSingle('uulogin') === '1';
};

export const isAdminLoggedIn = () => {
    return getSingle('isAdmin', true);
};

export const GetAuthToken = (isAdmin = false) => {
    return getSingle('uutoken', isAdmin);
};

const saveLastLogin = (name: string) => {
    cookies.set(lastLoginUserNameCookie, name, {
        path: '/',
        expires: moment().add(10, 'y').toDate(),
        sameSite: 'lax',
    });
};

export const saveData = (
    data: { user: User; token: string },
    remember = false,
    isAdmin = false,
    asAdmin = undefined
) => {
    let userData = {
        uulogin: '1',
        uuid: data.user?.id?.toString() || '',
        uutoken: data.token.toString(),
        isAdmin,
        asAdmin,
    };
    let expiresCustom = remember ? moment().add(1, 'y').toDate() : expires;

    cookies.set(isAdmin ? appAdminCookie : appCookie, encodeData(userData), {
        path: '/',
        expires: expiresCustom,
        sameSite: 'lax',
    });
    if (!isAdmin && data.user.name) {
        saveLastLogin(data.user.name);
    }
};

export const Logout = async (isAdmin = false) => {
    const cookie = isAdmin ? appAdminCookie : appCookie;
    const asAdminCheck = getSingle('asAdmin', isAdmin);

    if (asAdminCheck) {
        await LogoutUserAsAdmin(asAdminCheck);
    } else {
        cookies.remove(cookie.trim(), { path: '/' });
    }
};

export const LogoutUserAsAdmin = async (
    asAdmin: NonNullable<UserDataType['asAdmin']>
) => {
    try {
        const response = await PostApi('/logout', { asAdmin });
        const res = response?.data;
        if (res.status === 200) {
            cookies.remove(appCookie.trim(), { path: '/' });
        } else {
            throw new Error(res.message);
        }
    } catch (e) {
        if (e instanceof AxiosError) {
            throw new Error(
                e.response
                    ? e.response.data.message
                    : e.message
                    ? e.message
                    : 'Network error'
            );
        } else {
            throw e;
        }
    }
};

export const GetUserData = async () => {
    const response = await GetApi('profile');
    let res = response?.data;

    if (res.status === 200) {
        return res.data;
    } else {
        throw new Error(res.message);
    }
};

export const isExistingUser = () => {
    return Boolean(cookies.get(lastLoginUserNameCookie));
};
