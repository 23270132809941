import SubMenuItem, { SubMenuItemIcons } from '../Common/SubMenuItem';

import './SettingsSubMenu.scss';

const SettingsSubMenu = () => {
    return (
        <div className='SettingsSubMenu'>
            <SubMenuItem
                to='/settings/account'
                area='/settings/account'
                icon={SubMenuItemIcons.unified}
                title='Account Settings'
                hint='Profile Photo, name & language'
            />

            <SubMenuItem
                to='/settings/leads/statuses'
                area='/settings/leads'
                icon={SubMenuItemIcons.text}
                title='Lead Statuses'
                hint='Connected tools and services'
            />
            <SubMenuItem
                to='/settings/templates'
                area='/settings/templates'
                icon={SubMenuItemIcons.email}
                title='Email & Text Templates'
                hint='For Campaign steps and lead messages'
            />
            <SubMenuItem
                to='/settings/email-routing'
                area='/settings/email-routing'
                icon={SubMenuItemIcons.settings}
                title='Email Routing Settings'
                hint='Public e-mail addresses'
            />
            <SubMenuItem
                to='/settings/a2p-registrations'
                area='/settings/a2p-registrations'
                icon={SubMenuItemIcons.phone}
                title='A2P Registrations'
                hint=''
            />
            <SubMenuItem
                to='/settings/integrations'
                area='/settings/integrations'
                icon={SubMenuItemIcons.tools}
                title='Integrations'
                hint='Connections with external services'
            />
            <SubMenuItem
                to='/settings/leads/fields'
                area='/settings/leads/fields'
                icon={SubMenuItemIcons.usersSettings}
                title='Lead Fields'
                hint='Connected tools and services'
            />
        </div>
    );
};

export default SettingsSubMenu;
