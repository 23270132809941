import { Config } from '../../Config';
import { IconLinkButton } from './Button';

import './HelpDeskLink.scss';

type HelpDeskLinkProps = {
    title?: string;
    className?: string;
};

const HelpDeskLink = ({ title = 'Help', className }: HelpDeskLinkProps) => {
    const link = `${Config.freshdeskLoginLink}?client_id=${
        Config.freshdeskClientId
    }&redirect_uri=${encodeURIComponent(Config.freshdeskLoginRedirectLink)}`;

    return (
        <IconLinkButton
            className={`helpDeskLink ${className || ''}`}
            to={link}
            title={title}
            icon='fa fa-question'
        />
    );
};

export default HelpDeskLink;
