export const Config = {
    apiBaseURL: process.env.REACT_APP_API_URL || '',
    appSecret: process.env.REACT_APP_SECRET || '',
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID || '',
    facebookConfigId: process.env.REACT_APP_FACEBOOK_CONFIG_ID || '',
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
    linkedinClientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID || '',
    linkedinClientSecret: process.env.REACT_APP_LINKEDIN_CLIENT_SECRET || '',
    tinymceApiKey: process.env.REACT_APP_TINYMCE_API_KEY || '',
    testPhoneNumber: process.env.REACT_APP_TEST_PHONE_NUMBER || '',
    freshdeskClientId: process.env.REACT_APP_FRESHDESK_SSO_CLIENT_ID || '',
    freshdeskLoginLink: process.env.REACT_APP_FRESHDESK_SSO_LOGIN_LINK || '',
    freshdeskLoginRedirectLink:
        process.env.REACT_APP_FRESHDESK_SSO_LOGIN_REDIRECT_LINK || '',
    supportEmail: process.env.REACT_APP_SUPPORT_EMAIL || '',
    sparkUsersIds: process.env.REACT_APP_SPARK_USERS_IDS || '',
};
